import React, { useContext } from "react";
import styles from "./index.module.css";
import CheckIcon from "../../images/icons/check_icon.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";

function Congratulations() {
  const navigate = useNavigate();
  const { loanAmount } = useContext(DataContext) as DataContextType;

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <img
            src={CheckIcon}
            alt=""
            style={{
              height: "4em",
            }}
          />
          <h1
            style={{
              color: "#D32028",
              fontSize: "2.3em",
              fontWeight: "normal",
            }}
          >
            Congratulations!
          </h1>
          <h2
            style={{
              fontSize: "1.6em",
              fontWeight: "normal",
            }}
          >
            you are eligible to avail loan amount upto
          </h2>
          <h3
            style={{
              fontSize: "2.3em",
              fontWeight: "bold",
            }}
          >
            ₹ {loanAmount}
          </h3>
          <Button
            onPress={() => {
              // navigate("/sanction-letter");
              navigate("/select-emi");
            }}
            text={"Get sanction letter"}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Congratulations;
