import React from "react";
import styles from "./index.module.css";
import MailIcon from "../../../images/icons/mail.svg";
import PhoneIcon from "../../../images/icons/phone.svg";
import FeemonkText from "../../atoms/FeemonkText";

function Footer() {
  return (
    <div className={styles.footer}>
      <p className={styles.helpCenter}>Help centre</p>
      <div className={styles.contactDiv}>
        <span className={styles.contactItem}>
          <img
            
            className={styles.mailIconImage}
            src={MailIcon}
            alt=""
          />
          <p  className={styles.mailText}>hello@feemonk.com</p>
        </span>
        <span className={styles.contactItem}>
          <img className = {styles.PhoneIconImage}
            
            src={PhoneIcon}
            alt=""
          />
          <p  className= {styles.contactNumber}>+91 63096 31666</p>
        </span>
      </div>
      <p className={styles.copyrightText}>
        Copyright @ 2023 <FeemonkText /> All Rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
