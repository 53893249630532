export const queryStringToObject = (queryString: string) => {
  // Remove the leading '?' if it exists
  if (queryString.startsWith("?")) {
    queryString = queryString.slice(1);
  }

  // Split the query string into key-value pairs
  const pairs = queryString.split("&");
  const result: { [key: string]: string } = {}; // Add index signature

  // Iterate over each pair
  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    // Decode the URI components and assign to the result object
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return result;
};
