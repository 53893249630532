import React from "react";
import Styles from "./index.module.css";

interface InputTextProps {
  placeholder: string;
  style?: Object;
  type?: string;
  changeHandler?: (name?: any) => void;
  value?: string;
  disabled?: boolean;
  defaultValue?: any;
  autoComplete?: string;
  gridArea?: string;
  options?: {
    placeholder: string;
    value: string;
  }[];
  square?: boolean;
  readOnly?: boolean;
}

function InputText({
  placeholder,
  type,
  changeHandler,
  value,
  autoComplete,
  options,
  defaultValue,
  disabled,
  style,
  square,
  gridArea,
  readOnly,
  ...props
}: InputTextProps) {
  return (
    <div
      {...props}
      className={`${Styles.inputField} ${square && Styles.inputFieldSquare}`}
      style={{ gridArea: gridArea }}
    >
      {type === "textarea" ? (
        <textarea
          readOnly={readOnly}
          onChange={changeHandler}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
        ></textarea>
      ) : type === "option" ? (
        <select
          name={placeholder}
          id={placeholder}
          disabled={disabled}
          onChange={changeHandler}
          value={value}
        >
          <option value="none" disabled hidden>
            {placeholder}
          </option>
          {options &&
            options.map((item, index) => (
              <option value={item.value} key={index} hidden={index === 0}>
                {item.placeholder}
              </option>
            ))}
        </select>
      ) : type === "toggleSwitch" ? (
        <div className={Styles.toggleSwitch}>
          <label className={Styles.switch}>
            <input
              type="checkbox"
              checked={!!value}
              defaultValue={defaultValue}
              disabled={disabled}
              className={Styles.switchInput}
              onChange={changeHandler}
              readOnly={readOnly}
            />
            <span className={`${Styles.slider} ${Styles.round}`}></span>
          </label>
        </div>
      ) : (
        <input
          style={style}
          onChange={changeHandler}
          type={type ? type : "text"}
          value={value}
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required
          readOnly={readOnly}
        />
      )}
    </div>
  );
}

export default InputText;
