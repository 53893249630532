import React, { useContext } from "react";
import styles from "./index.module.css";
import CheckIcon from "../../images/icons/check_icon.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";

function Approval() {
  const navigate = useNavigate();
  const { loanAmount } = useContext(DataContext) as DataContextType;

  const statusSuccess={
    value:"success"
  }
  const statusNMD={
    value:"NMD"
  }
  const statusFailure={
    value:"failure"
  }

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
        <div style={{display:"flex",justifyContent: 'space-around',gap:'1rem',flexDirection: 'row',flexWrap: 'wrap',}}>
         <div style={{display:'flex'}}>
          <h1
            style={{
              color: "#D32028",
              fontSize: "1.3em",
              fontWeight: "normal",
            }}
          >
            Digilocker  <img
            src={CheckIcon}
            alt=""
            style={{
              height: "2em",
            }}
          />
          </h1>
          </div>
         <div style={{display:'flex'}}>
          <h1
            style={{
              color: "#D32028",
              fontSize: "1.3em",
              fontWeight: "normal",
            }}
          >
            Selfie  <img
            src={CheckIcon}
            alt=""
            style={{
              height: "2em",
            }}
          />
          </h1>
          </div>
         <div style={{display:'flex'}}>
          <h1
            style={{
              color: "#D32028",
              fontSize: "1.3em",
              fontWeight: "normal",
            }}
          >
            Agreement  <img
            src={CheckIcon}
            alt=""
            style={{
              height: "2em",
            }}
          />
          </h1>
          </div>
         <div style={{display:'flex'}}>
          <h1
            style={{
              color: "#D32028",
              fontSize: "1.3em",
              fontWeight: "normal",
            }}
          >
            Emandate  <img
            src={CheckIcon}
            alt=""
            style={{
              height: "2em",
            }}
          />
          </h1>
          </div>
         </div>
          <div style={{display:"flex",justifyContent: 'space-around',gap:'1rem',flexDirection: 'row',flexWrap: 'wrap',}}>
          <Button
          color="success"
            onPress={() => {
              // navigate("/sanction-letter");
              window.open(`https://feemonk-checkout-feemonk-new.vercel.app/${btoa(JSON.stringify(statusSuccess))}`,"_self");
            }}
            text={"Success"}
          />
          <Button
          color="NMD"
            onPress={() => {
              // window.open("/sanction-letter");
              window.open(`https://feemonk-checkout-feemonk-new.vercel.app/${btoa(JSON.stringify(statusNMD))}`,"_self");
            }}
            text={"Need More Details"}
          />
          <Button
          color="failure"

            onPress={() => {
              // window.open("/sanction-letter");
              window.open(`https://feemonk-checkout-feemonk-new.vercel.app/${btoa(JSON.stringify(statusFailure))}`,"_self");
            }}
            text={"Failure"}
          />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Approval;
