import React from "react";
import styles from "./index.module.css";
import PanIcon from "../../images/icons/pan_annimation/main.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";

function ValidatingPan() {
  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <img
            src={PanIcon}
            alt=""
            style={{
              height: "20rem",
            }}
          />
          <h2>Validating your PAN....</h2>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ValidatingPan;
