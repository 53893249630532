import React, { useContext, useEffect } from "react";
import styles from "./index.module.css";
import CheckIcon from "../../images/icons/check_icon.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { queryStringToObject } from "../../utils/queryStringToObject";
import { ApplicantStage } from "../../utils/applicationStage";

function ParkingPage() {
  const navigate = useNavigate();
  const { loanAmount } = useContext(DataContext) as DataContextType;
  const location = useLocation();

  const loc = useLocation();
  console.log("body-->", loc);
  useEffect(() => {
    const setAAStatus = async (status: any) => {
      try {
        const response1 = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
          {
            method: "POST",
            body: JSON.stringify({
              // memberId: location?.state?.data.userId,
              memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result1 = await response1.json();
        const authToken = result1.data;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${authToken}`);

        const response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/finbox/status`,
          {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({ ...status }),
          }
        );
        const body = { ...status };
        console.log("body-->", body);
        const result = await response.json();

        console.log(result);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    if (loc.search) {
      console.log(queryStringToObject(loc.search));
      setAAStatus(queryStringToObject(loc.search));
    }
  }, [loc, location]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${location?.state?.data?.applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/address", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              // navigate("/parking-page", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.AwaitingApproval:
              // navigate("/parking-page", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.CoapplicantRequired:
              // navigate("/parking-page", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              // navigate("/parking-page", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/enter-pan`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [location?.state?.data?.applicationId, location?.state?.data, navigate]);

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <img
            src={CheckIcon}
            alt=""
            style={{
              height: "4em",
              marginTop: "4rem",
            }}
          />
          <h1
            style={{
              color: "#2c6ce3",
              fontSize: "2.3em",
              fontWeight: "normal",
            }}
          >
            Thank you!
          </h1>
          <h2
            style={{
              fontSize: "1.6em",
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            Your application is under review. Please wait until we evaluate your
            application.
          </h2>
          {/* <h3
            style={{
              fontSize: "2.3em",
              fontWeight: "bold",
            }}
          >
            ₹ {loanAmount}
          </h3> */}
          <div className={styles.holdOnTextContainer}>
            <p className={styles.holdOnHelperText}>
              If you have a problem, please contact us:
            </p>
            <p className={styles.holdOnHelperTextSmall}>hello@feemonk.com</p>
          </div>
          {/* <Button
            onPress={() => {
              // navigate("/sanction-letter");
              navigate("/select-emi");
            }}
            text={"Get sanction letter"}
          /> */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ParkingPage;
