import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import styles from "./index.module.css";
import KYC from "../../images/icons/kyc.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import LoanStepCard from "../../components/molecules/Card";
import InputText from "../../components/atoms/InputText";
import Button from "../../components/atoms/Button";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { useLocation, useNavigate } from "react-router-dom";
import { updateAddress } from "../../services/application";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { ApplicantStage } from "../../utils/applicationStage";
import Label from "../../components/atoms/Label";

import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';

const  Address = forwardRef((props,ref)=> {
  
  const [currentAddress, setCurrentAddress] = useState("yes");
  const { panProData, userId } = useContext(DataContext) as DataContextType;
  const navigate = useNavigate();
  const [newAuthToken, setNewAuthToken] = useState("");
  //const [AddressInputFieldsDisable,setAddressInputFieldsDisable] = useState(true)
  
  const [permanentAddressInputFieldsDisable,setPermanentAddressInputFieldsDisable] = useState(true)
  const [currentAddressInputFieldsDisable,setCurrentAddressInputFieldsDisable]= useState(true)

{/*
  const [userData, setUserData] = useState({
    applicationId: "",
    userId: "",
    instituteId: "",
    firstName: "",
    lastName: "",
    panId: "",
    dateOfBirth: "",
    email: "",
    mobile: "",
    course: "",
    fees: 0,
    studentName: "",
    instituteName: "",
    redirectUrl: "",
    channelId: 0,
  })
*/}



const [userData, setUserData] = useState({
  applicationId: "",
  userId: "",
  instituteId: "",
  firstName: "",
  lastName: "",
  panId: "",
  dateOfBirth: "",
  email: "",
  mobile: "",
  course: "",
  fees: 0,
  studentName: "",
  instituteName: "",
  redirectUrl: "",
  channelId: 0,
})


  console.log("this is userData from address",userData)

  const location = useLocation();
  console.log("this is location from address",location)
 

  const [state, setState] = useState({
    currentAddress: panProData?.currentAddress || "",
    currentCity: panProData?.currentCity || "",
    currentState: panProData?.currentState || "",
    currentPincode: panProData?.currentPincode || "",
    permanentAddress: "",
    permanentCity: "",
    permanentState: "",
    permanentPincode: "",
    userId: location?.state?.data?.userId || "",
    applicationId: location?.state?.data?.applicationId || "",
  });

  console.log("this is state value",state)


  useImperativeHandle(ref,()=>{
    return {
      submitAddress,
    }
  })

  
  useEffect(() => {
    const getDetails = async () => {
      const response1 = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
        method: "POST",
        body: JSON.stringify({
          // memberId: location?.state?.data.userId,
          memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result1 = await response1.json();
      const authToken = result1.data;
      console.log("this is auth token from address",authToken)

      

      setNewAuthToken(authToken);
      const token = sessionStorage.getItem("data")

      try {
        const userDetailsDecoded = JSON.parse(atob(token || ""));
        setUserData(userDetailsDecoded)
      } catch (error) {
        console.error("Invalid JSON: ", error);
      }
      

     
      
      
{/*
      if (location?.state?.data?.userId) {
        const response2 = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/users/profile-details?userId=${location?.state?.data?.userId}&channelId=5`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        

        const result2 = await response2.json();

        console.log(result2);

        setState({
          currentAddress: result2?.data?.currentAddress || "",
          currentCity: result2?.data?.currentCity || "",
          currentState: result2?.data?.currentState || "",
          currentPincode: result2?.data?.currentPincode || "",
          permanentAddress: result2?.data?.permanentAddress || "",
          permanentCity: result2?.data?.permanentCity || "",
          permanentState: result2?.data?.permanentState || "",
          permanentPincode: result2?.data?.permanentPincode || "",
          userId: location?.state?.data?.userId || "",
          applicationId: location?.state?.data?.applicationId || "",
        });
      } else {
        setState({
          currentAddress: "",
          currentCity: "",
          currentState: "",
          currentPincode: "",
          permanentAddress: "",
          permanentCity: "",
          permanentState: "",
          permanentPincode: "",
          userId: location?.state?.data?.userId || "",
          applicationId: location?.state?.data?.applicationId || "",
        });
      }

      */}

      {/*
      const response2 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/users/profile-details?userId=${location?.state?.data?.userId}&channelId=5`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      */}

      {/*

      const response2 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/users/profile-details?userId=${userDetailsDecoded.userId}&channelId=5`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      

      const result2 = await response2.json();

      console.log(result2);

      setState({
        currentAddress: result2?.data?.currentAddress || "",
        currentCity: result2?.data?.currentCity || "",
        currentState: result2?.data?.currentState || "",
        currentPincode: result2?.data?.currentPincode || "",
        permanentAddress: result2?.data?.permanentAddress || "",
        permanentCity: result2?.data?.permanentCity || "",
        permanentState: result2?.data?.permanentState || "",
        permanentPincode: result2?.data?.permanentPincode || "",
        //userId: location?.state?.data?.userId || "",
       //applicationId: location?.state?.data?.applicationId || "",
       userId:userDetailsDecoded.userId || "",
       applicationId:userDetailsDecoded.applicationId || "",
      });
      */}
    
    };

    getDetails();
    console.log("this is useeffect from address")
  }, []);

  const userToken = sessionStorage.getItem("data")
  {/* summary api */}
  useEffect(() => {
   
    if (userToken) {
      try {
        
        
        //setUserData(JSON.parse(atob(data || "")));

        const userDetailsDecoded = JSON.parse(atob(userToken || ""))
        console.log("this is atob",userDetailsDecoded)


        fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${userDetailsDecoded.userId}&applicationId=${userDetailsDecoded.applicationId}`
        )
          .then((res) => res.json())
          .then((res) => {
            console.log("This is res stage", res?.data?.stage);
            
            const userInfo = res?.data 
            console.log("this is userInfo from address",userInfo)
            //setUserData({...userInfo,...userDetailsDecoded})
            setUserData(userInfo)
            setState({
              currentAddress: res?.data?.currentAddress || "",
              currentCity: res?.data?.currentCity || "",
              currentState: res?.data?.currentState || "",
              currentPincode: res?.data?.currentPincode || "",
              permanentAddress: res?.data?.permanentAddress || "",
              permanentCity: res?.data?.permanentCity || "",
              permanentState: res?.data?.permanentState || "",
              permanentPincode: res?.data?.permanentPincode || "",
              //userId: location?.state?.data?.userId || "",
             //applicationId: location?.state?.data?.applicationId || "",
             userId:userDetailsDecoded.userId || "",
             applicationId:userDetailsDecoded.applicationId || "",
            });
            
            
            
          })
          .catch((error) => {
            console.error("Error fetching user summary:", error);
          });
        

      } catch (error) {}
    }
  }, [userToken]);

  const [stage,setStage] = useState(null)

  {/*
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${location?.state?.data?.applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setStage(res.data.skipStatement)
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data ,stage: res.data.skipStatement},
              });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data,stage: res.data.skipStatement },
              });
              // navigate("/address", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/enter-pan`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [location?.state?.data?.applicationId, location?.state?.data, navigate]);

  */}

  const submitAddress = () => {
    // setPanDetails(PANzoopData);
    return updateAddress(
      {
        userId: state.userId,
        applicationId: state.applicationId,
        currentAddress: state.currentAddress,
        currentCity: state.currentCity,
        currentState: state.currentState,
        currentPincode: state.currentPincode,
        permanentAddress:
        state.permanentAddress
            ? state.permanentAddress
            : state.currentAddress,
        permanentCity:
        state.permanentCity ? state.permanentCity : state.currentCity,
        permanentState:
        state.permanentState ? state.permanentState : state.currentState,
        permanentPincode:
        state.permanentPincode
            ? state.permanentPincode
            : state.currentPincode,
        channelId: 5,
      },
      newAuthToken
    )
      .then((result) => {
        console.log(result);
        //navigate("/work-details", { state: { data: location?.state?.data ,stage} });
        return result
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log("this is address")

  const handleEditCurrentAddress = () => {
    setCurrentAddressInputFieldsDisable(false)
  }

  const handleEditPermanentAddress = () =>{
    setPermanentAddressInputFieldsDisable(false)

  }
  

  return (
   // <div className={styles.body}>
      //<div className={styles.backdrop}>
        //<Navbar /> 
        <div className={styles.container}>
          <div style ={{display:"flex", flexDirection:"row",justifyContent:"space-between",alignItems:"space-between",gap:"1rem"}}> 
          <LoanStepCard
            // description="Permanent Address & Current Location"
            //title="KYC"
            //image={KYC}

            title="Address Details"
            image={KYC}

            
            
          />
          {/*
          <EditNoteSharpIcon sx={{ fontSize: 40 }} color="primary" onClick ={handleEditAddress}/>
          */}
          </div>
          <div className={styles.content}>

{/* Current address begin */}
            
            <div className={styles.addressWidth}> 
            <div
              style={{
                padding: "1rem",
                background: "var(--box-background-dark)",
                border: "1px solid var(--primary-border-dark)",
                borderRadius: "12px 12px 0px 0px",
                display:"flex",
                justifyContent:"space-between",
                alignItems:"flex-end"
              }}
            >
              <p style={{ fontSize: "1em", fontWeight: "bold", margin:"0.5em" }}>
                Current Address
              </p>
              {/*
              <EditNoteSharpIcon sx={{ fontSize: 40 }} color="primary" onClick ={handleEditPermanentAddress}/>
              */}
              <button style = {{color:"#2c6ce3", border:"1px solid #2c6ce3", padding:"0.2rem 1rem", borderRadius:"10px"}} onClick ={handleEditPermanentAddress}>Edit</button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                gap: "1rem",
                border: "1px solid var(--primary-border-dark)",
                background: "var(--box-background)",
                padding: "1rem",
                boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              
              <div //className={styles.addressInputGrid}
              >
                <div style = {{marginBottom:"20px"}}>
              <Label text="Door No" />
                <InputText
                  gridArea="door"
                  square
                  placeholder="Door No."
                  value={state.currentAddress}
                  changeHandler={(e) =>
                    setState((s) => ({ ...s, currentAddress: e.target.value }))
                  }
                  disabled={permanentAddressInputFieldsDisable}
                />
                </div>
                
                
                {/* <InputText
                  gridArea="street"
                  square
                  placeholder="Street / Landmark"
                  value={state.currentAddress}
                /> */}
                <div style={{ display: "block" }}>
                  <div style = {{marginBottom:"20px"}}>
                <Label text="City" />
                  <InputText
                    gridArea="city"
                    square
                    placeholder="City"
                    value={state.currentCity}
                    changeHandler={(e) =>
                      setState((s) => ({ ...s, currentCity: e.target.value }))
                    }
                    disabled={permanentAddressInputFieldsDisable}
                  />

</div>

<div style = {{marginBottom:"20px"}}>
                  <Label text="State" />
                  <InputText
                    gridArea="state"
                    square
                    placeholder="State"
                    value={state.currentState}
                    changeHandler={(e) =>
                      setState((s) => ({ ...s, currentState: e.target.value }))
                    }
                    disabled={permanentAddressInputFieldsDisable}
                  />
                  </div>

                  <div style = {{marginBottom:"20px"}}>
                  <Label text="PinCode" />
                  <InputText
                    gridArea="pin"
                    square
                    placeholder="Pincode"
                    value={state.currentPincode}
                    changeHandler={(e) =>
                      setState((s) => ({
                        ...s,
                        currentPincode: e.target.value,
                      }))
                    }
                    disabled={permanentAddressInputFieldsDisable}
                  />

                  </div>
                  {/* <Button
                    onPress={() => {}}
                    text={"Save"}
                    fullWidth={false}
                    secondary
                  /> */}
                </div>
              </div>
              {/*
              <div>
                <p
                  style={{
                    color: "#D32028",
                    fontSize: "1.2em",
                  }}
                >
                  Note
                </p>
                <p style={{ fontSize: "1em" }}>
                  The following address details are obtained from your e-kyc,
                  please feel free to edit in case of changes
                </p>
              </div>
              */}
              {/* <br /> */}
            </div>
            </div>

            {/*<br /> */}

            {/* Permanent address begin */}

            <div className={styles.addressWidth}   
            >
              <div
            style={{
              padding: "1rem",
              background: "var(--box-background-dark)",
              border: "1px solid var(--primary-border-dark)",
              borderRadius: "12px 12px 0px 0px",
              display:"flex",
                justifyContent:"space-between",
                alignItems:"flex-end"
            }}
          >
            <p style={{ fontSize: "1em", fontWeight: "bold",margin:"0.5em"  }}>
            Permanent Address
            </p>
            {/*
            <EditNoteSharpIcon sx={{ fontSize: 40 }} color="primary" onClick ={handleEditCurrentAddress}/>
            */}
            <button style = {{color:"#2c6ce3", border:"1px solid #2c6ce3", padding:"0.2rem 1rem", borderRadius:"10px"}} onClick ={handleEditCurrentAddress}>Edit</button>
          </div>
              {/* <strong style={{ fontSize: "1em" }}>
                Is this your current address?
              </strong> */}
              <div className={styles.inputField} 
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                gap: "1rem",
                border: "1px solid var(--primary-border-dark)",
                background: "var(--box-background)",
               
                padding: "1rem",
                boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                borderRadius: "0px 0px 12px 12px",
                
              }}
              >
                {/* <Label text="Account Type" /> */}
                {/* <div
                  onChange={(event) =>
                    setCurrentAddress((event.target as HTMLInputElement).value)
                  }
                  defaultValue="yes"
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <input
                      style={{ width: "1em" }}
                      type="radio"
                      value="yes"
                      name="yes"
                      checked={currentAddress === "yes"}
                    />{" "}
                    <span style={{ fontSize: "1em" }}>Yes</span>
                  </div>
                  <div>
                    <input
                      style={{ width: "1em" }}
                      type="radio"
                      value="no"
                      name="student"
                      checked={currentAddress === "no"}
                    />{" "}
                    <span style={{ fontSize: "1em" }}> No</span>
                  </div>
                </div> */}
             
                  <div>
                    
                    {/* <div
                      style={{
                        border: "0.5px solid rgba(181, 181, 181, 0.3)",
                      }}
                    ></div>
                    <br /> */}


            {/*
                    <p style={{ fontSize: "1em", fontWeight: "bold" }}>
                      Current Address
                    </p>
                    */}
                    
                    
                    <div //className={styles.addressInputGrid}
                    >

<div style = {{marginBottom:"20px"}}>
                      <Label text="Door No" />
                      <InputText
                        gridArea="door"
                        square
                        placeholder="Door No."
                        value={state.permanentAddress}
                        changeHandler={(e) => {
                          setState((s) => ({
                            ...s,
                            permanentAddress: e.target.value,
                          }));
                        }}
                        disabled={currentAddressInputFieldsDisable}
                      />

                      </div>
                      <div style={{ display: "block" }}>

                      <div style = {{marginBottom:"20px"}}>
                      <Label text="City" />
                        <InputText
                          gridArea="city"
                          square
                          placeholder="City"
                          value={state.permanentCity}
                          changeHandler={(e) => {
                            setState((s) => ({
                              ...s,
                              permanentCity: e.target.value,
                            }));
                          }}
                          disabled={currentAddressInputFieldsDisable}
                        />
                        </div>

                        <div style = {{marginBottom:"20px"}}>
                        <Label text="State" />
                        <InputText
                          gridArea="state"
                          square
                          placeholder="State"
                          value={state.permanentState}
                          changeHandler={(e) => {
                            setState((s) => ({
                              ...s,
                              permanentState: e.target.value,
                            }));
                          }}
                          disabled={currentAddressInputFieldsDisable}
                        />
                        </div>

                        <div style = {{marginBottom:"20px"}}>
                        <Label text="PinCode" />
                        <InputText
                          gridArea="pin"
                          square
                          placeholder="Pincode"
                          value={state.permanentPincode}
                          changeHandler={(e) => {
                            setState((s) => ({
                              ...s,
                              permanentPincode: e.target.value,
                            }));
                          }}
                          disabled={currentAddressInputFieldsDisable}
                        />

                        </div>
                        {/* <Button
                          onPress={() => {}}
                          text={"Save"}
                          fullWidth={false}
                          secondary
                        /> */}
                      </div>
                    </div>
                  </div>
              
              </div>
            </div>


          </div>


{/*
          <Button
            text={"Save & Next"}
            onPress={() => {
              submitAddress();
            }}
            //insureFin={location?.state?.data?.channelId === 5}
            insureFin={userData.channelId === 5}
            disabled={
              !state.currentAddress ||
              !state.currentCity ||
              !state.currentState ||
              !state.currentPincode ||
              (currentAddress === "no" &&
                (!state.permanentAddress ||
                  !state.permanentCity ||
                  !state.permanentState ||
                  !state.permanentPincode))
            }
          />
          */}
        </div>

       // <Footer />
      //</div>
    //</div>

  );
})

export default Address;
