import React from 'react'
import logo from './Assets/images/logo.svg'

export default function Terms() {
  return (
    <div style={{padding:'1rem',display:'block',justifyContent: 'center',alignContent: 'center',}}>
    <div style={{display:'flex',justifyContent: 'center',alignContent: 'center',}}>
    <img src={logo}/>
    </div>
    <div>
<h4 style={{paddingLeft:'1rem'}}>SELF-DECLARATION AND UNDERTAKING:</h4>
<ul>
  <li>
I hereby apply for the Loan facility from the Lender as specified above.
</li>
<li>
I represent that the information and details provided in this Application Form and the documents submitted by me are true, correct and that I have not withheld any information.
</li>
<li>
I have read and understood the fees and charges applicable to the Loan that I may avail from time to time.
</li>

<li>
I confirm that no insolvency proceedings or suits for recovery of outstanding dues have been initiated and / or are pending against me.
</li>

<li>
I hereby authorize Lender to exchange or share information and details relating to this Application Form its group companies or any third party, as may be required or deemed fit, for the purpose of processing this loan application and/or related offerings or other products / services that I may apply for from time to time.
</li>

<li>
I hereby consent to and authorize Lender to increase or decrease the credit limit assigned to me basis Lender's internal credit policy.
</li>

<li>
By submitting this Application Form, I hereby expressly authorize Lender to send me communications regarding various financial products offered by or from Lender, its group companies and/ or third parties through telephone calls/ SMSs /emails/ post etc. including but not limited to promotional communications. And confirm that I shall not challenge receipt of such communications as unsolicited communication, defined under TRAI Regulations on Unsolicited Commercial Communications under the Do Not Call Registry.
</li>

<li>
I understand and acknowledge that Lender has the absolute discretion, without assigning any reasons to reject my application and that Lender is not liable to provide me a reason for the same.
</li>
<li>
That Lender shall have the right to make disclosure of any information relating to me including personal information, details in relation to Loan, defaults, security, etc. to the Credit Information Bureau of India
(CIBIL) and/or any other governmental/regulatory/statutory or private agency I entity, credit bureau, RBI, CKYCR, including publishing the name as part of willful defaulter's list from time to time, as also use for KYC information verification, credit risk analysis, or for other related purposes.
</li>

<li>
I agree and accept that Lender may in its sole discretion, by its self or through authorised persons, advocate, agencies, bureau, etc. verify any information given, check credit references, employment details and obtain credit reports to determine creditworthiness from time to time.
</li>

<li>
That I have not taken any loan from any other bank/ finance company unless specifically declared by me.
</li>

<li>
  
That the funds shall be used for the Purpose specified in above and will not be used for speculative or antisocial purpose.
</li>

<li>
I have understood and accepted the late payment and other default charges listed above.
</li>

<li>
I hereby confirm that I contacted Lender for my requirement of personal loan and no representative of Lender has emphasized me directly I indirectly to make this application for the Loan.
</li>

<li>
I hereby confirm having read and understood the Standard Terms and Conditions applicable to this Loan and are signing this Application Form after understanding of each term.
</li>
</ul>
</div>
</div>
  )
}
