import React, { useState } from "react";
import styles from "./index.module.css";
import ParentIcon from "../../images/icons/parent_icon.svg";
import CoAppIcon from "../../images/icons/co_app_icon.svg";
import StudentIcon from "../../images/icons/student_icon.svg";
import Button from "../../components/atoms/Button";
import CourseDetailsIcon from "../../images/static_assests/course_details.svg";
import LoanStepCard from "../../components/molecules/Card";
import { useNavigate } from "react-router-dom";
import InputText from "../../components/atoms/InputText";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import ArrowLeft from "../../images/icons/arrow_left.svg";

function CourseDetails() {
  const [active, setActive] = useState<"PARENT" | "STUDENT" | "COAPP">(
    "PARENT"
  );

  const navigate = useNavigate();

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <LoanStepCard
            // description="Permanent Address & Current Location"
            title="Course details"
            image={CourseDetailsIcon}
          />
          <div className={styles.courseDetailsTabs}>
            <div
              className={
                active === "PARENT" ? styles.tabActive : styles.tabInactive
              }
              onClick={() => {
                setActive("PARENT");
              }}
            >
              <img src={ParentIcon} alt="ParentTab" />
              <p>Parent</p>
            </div>
            <div
              className={
                active === "STUDENT" ? styles.tabActive : styles.tabInactive
              }
              onClick={() => {
                setActive("STUDENT");
              }}
            >
              <img src={StudentIcon} alt="ParentTab" />
              <p>Student</p>
            </div>
            <div
              className={
                active === "COAPP" ? styles.tabActive : styles.tabInactive
              }
              onClick={() => {
                setActive("COAPP");
              }}
            >
              <img src={CoAppIcon} alt="ParentTab" />
              <p>Co-applicant</p>
            </div>
          </div>
          <div>
            {active === "PARENT" && (
              <>
                <div
                  style={{
                    padding: "1rem",
                    background: "var(--box-background-dark)",
                    border: "1px solid var(--primary-border-dark)",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>My child details</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    // alignItems: "center",
                    gap: "1rem",
                    border: "1px solid var(--primary-border-dark)",
                    background: "var(--box-background)",
                    padding: "1rem",
                    boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                    borderRadius: "0px 0px 12px 12px",
                  }}
                >
                  <div className={styles.addressInputGrid}>
                    <InputText gridArea="door" square placeholder="Full Name" />
                    <InputText
                      gridArea="street"
                      square
                      placeholder="School Name"
                    />
                    <InputText
                      gridArea="city"
                      square
                      placeholder="Date of Birth"
                    />
                    <InputText
                      gridArea="state"
                      square
                      placeholder="Class/ Standard"
                    />
                    <InputText
                      gridArea="pin"
                      square
                      placeholder="Annual Income"
                    />
                    <Button
                      onPress={() => {}}
                      text={"Save"}
                      fullWidth={false}
                      secondary
                    />
                  </div>
                </div>
              </>
            )}
            {active === "COAPP" && (
              <>
                <div
                  style={{
                    padding: "1rem",
                    background: "var(--box-background-dark)",
                    border: "1px solid var(--primary-border-dark)",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>My child details</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    // alignItems: "center",
                    gap: "1rem",
                    border: "1px solid var(--primary-border-dark)",
                    background: "var(--box-background)",
                    padding: "1rem",
                    boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                    borderRadius: "0px 0px 12px 12px",
                  }}
                >
                  <div className={styles.addressInputGrid}>
                    <InputText gridArea="door" square placeholder="Full Name" />
                    <InputText
                      gridArea="street"
                      square
                      placeholder="School Name"
                    />
                    <InputText
                      gridArea="city"
                      square
                      placeholder="Date of Birth"
                    />
                    <InputText
                      gridArea="state"
                      square
                      placeholder="Class/ Standard"
                    />
                    <InputText
                      gridArea="pin"
                      square
                      placeholder="Annual Income"
                    />
                    <Button
                      onPress={() => {}}
                      text={"Save"}
                      fullWidth={false}
                      secondary
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <Button
            onPress={() => {
              navigate("/sanctions");
            }}
            text={"Get loan offer"}
            imageRight={ArrowLeft}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CourseDetails;
