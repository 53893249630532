import {userConstants} from '../constants/user.constants'


let user = sessionStorage.getItem('user');
const initialState = user ? { loggedIn: false, user, isLoading: false } : {};


export function repayments(state = initialState, action) {
  console.log("inside repayments function", action)
  switch (action.type) {
    case userConstants.GET_REPAYMENTS_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.GET_REPAYMENTS_SUCCESS:
      console.log("staaaa",action)
      return {
        loggedIn: true,
        getRepaymentsData: action.res,
        isLoading : false
      };
    case userConstants.GET_REPAYMENTS_FAILURE:
      return {
        isLoading : false
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
export function emiHistory(state = initialState, action) {
  console.log("inside emiHistory function", action)
  switch (action.type) {
    case userConstants.GET_EMIHISTORY_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.GET_EMIHISTORY_SUCCESS:
      console.log("staaaa",action)
      return {
        loggedIn: true,
        getEmiHistoryData: action.res,
        isLoading : false
      };
    case userConstants.GET_EMIHISTORY_FAILURE:
      return {
        isLoading : false
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
