import Navbar from './Navbar'
import Hero from './Hero'
import React, { useState } from "react";
import moment from 'moment';
import { createRoot } from 'react-dom/client';
import App1 from './Reciept/App1'
import Educationcard from '../Home/Educationcard';
import {useDispatch,useSelector} from 'react-redux'
import {userActions} from '../../actions/user.actions'
import { Table } from 'reactstrap';
import { Container, Button, Grid, Paper, Box, colors } from "@mui/material";
import { Homestyle } from "../Home/Style";
import EMI from "../../Assets/images/EMI.png"
import back from "../../Assets/images/arrow-left-circle.png"
import {useNavigate,useLocation} from 'react-router-dom'
import wallet from '../../Assets/images/wallet.png'
import axios from 'axios';
import icon from "../../Assets/images/i-icon.png"
import Tooltip from '@mui/material/Tooltip';
import download from "../../Assets/images/download.png"
import "./Repayments.css"
import App from './Statement/App';

import Footer from '../Home/Footer'
 
export default function Index() {
  const user = sessionStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const location=useLocation();
  const status=location?.state?.loanStatus
  const getEmiHistory=useSelector((state)=>state?.emiHistory?.getEmiHistoryData?.data?.emiHistory?.data)
  const [emiHistory,setEmiHistory]=useState(null)
  const [count, setCount] = useState(0); 
  const [totalCount, setTotalCount] = useState(0);
  const dispatch=useDispatch()
  const urlGetEmiHistory=`/repayments/emi-history/${sessionStorage.getItem('loanId')}`
  const [repaymentdetails,setRepaymentDetails] = useState("")

  
  React.useEffect(() => {
    const repaymentsUrl = `${process.env.REACT_APP_DASHBOARD_URL}/loan-repayment/profile?loanId=${sessionStorage.getItem("LoanId")}`
         
    axios.get(repaymentsUrl,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{console.log("res",res?.data?.data)
      setRepaymentDetails(res?.data?.data)

    }).catch((error)=>console.log(error),"error")

    const url = `${process.env.REACT_APP_DASHBOARD_URL}/emis/for-loan?loanId=${sessionStorage.getItem("LoanId")}`
         
        axios.get(url,{
            headers:{
              Authorization:`Bearer ${user}`
          }
          }).then((res)=>{setTableData(res?.data?.data)
            const unpaidItems = res?.data?.data.filter(item => item.status === 2);
            setCount(unpaidItems.length);
            setTotalCount(res?.data?.data.length)
           
        }).catch((error)=>{console.log("error",error);})

        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };

    
  }, [])

  
  
  const navigate=useNavigate();
  
      const [tableData, setTableData] = useState(getEmiHistory?.emis);
      const [selectedItems, setSelectedItems] = useState([]);
      const [totalEmiAmount, setTotalEmiAmount] = useState(0);
      const [overdueCharges, setOverdueCharges] = useState(0);
      const [chequeBounceCharges, setChequeBounceCharges] = useState(0);
      const [checkedCount, setCheckedCount] = useState(0);
 
      const handleCheckboxClick = (array,index) => {

        setSelectedItems([]);
        setTotalEmiAmount(0)
        setOverdueCharges(0)
        setChequeBounceCharges(0)
        
        if(document.getElementById(`${index}`).checked==false)
        {
          setCheckedCount((prevCount) => prevCount - 1);
            for(let i=0;i<tableData?.length;i++)
            {
                document.getElementById(`${i}`).checked=false
                setSelectedItems([]);
                setTotalEmiAmount(0)
                setOverdueCharges(0)
                setChequeBounceCharges(0)
            }
        }
        else
        {
          setCheckedCount((prevCount) => prevCount + 1);
            for(let i=index;i>=0;i--)
            {   
        
                if(array[i].status!=2)
                {
                    
                    document.getElementById(`${i}`).checked=true
                    setSelectedItems((old)=>[...old,array[i].emiId]);
                    setTotalEmiAmount((old)=>old+array[i].emiAmount)
                    setOverdueCharges((old)=>old+array[i].overdueCharges)
                    setChequeBounceCharges((old)=>old+array[i].bounceCharges)
                }
            }
        }
        
    
         
        }       

        function formatNumberWithCommas(number) {
          return number?.toLocaleString('en-IN'); 
        }
        const handleClick = (component, componentName) => {
          const newWindow = window.open('', '_blank');
          const container = newWindow.document.createElement('div'); // Creating a container element
          newWindow.document.body.appendChild(container); // Appending container to body
          createRoot(container).render(component); // Rendering into the container using createRoot
        setTimeout(() => {
          newWindow.close()
        }, 1000);
        };
  return (
    <div className='home-bg ' >

        <Navbar/>

       <Homestyle>
        <Container>
          <Paper className="paper-setting">
          <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <div
              onClick={() => navigate(-1)}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img style={{ height: "22px", width: "22px" }} src={back} />
              <p
                style={{
                  fontFamily: "Inter-Medium",
                  fontSize: "14px",
                  fontWeight: "500",
                  padding: 0,
                  margin: 0,
                  paddingLeft: "5px",
                }}
              >
                Back
              </p>
            </div>
            <button
                   style={{
                     height: "40px",
                     display: "flex",
                     alignItems: "center",
                     borderStyle: "solid",
                     borderColor: "#D0D5DD",
                     borderWidth: "1px",
                     borderRadius: "5px",
                     padding: "5px",
                     background: "none",width:'8.5rem'
                   }} 
                   onClick={() => handleClick(<App repaymentDet={repaymentdetails} emiDet={tableData} />, 'Statement')}
                 >
                   <img src={download} style={{height:'17px'}}/>
                   <span
                     style={{
                       fontFamily: "Inter-Medium",
                       fontSize: "14px",
                       marginLeft: "3px",whiteSpace:'nowrap'
                     }}
                     
                   >
                   <App repaymentDet={repaymentdetails} emiDet={tableData} />
                    
                   </span>
                 </button>
                  </div>
                
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <p
                    style={{
                      fontFamily: "Inter-Medium",
                      fontSize: "18px",
                      marginTop: "20px",paddingLeft: "5px",
                    }}
                  >
                    Repayment details
                    <span>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "#DFF8E4",
                          borderRadius: "8px",
                          marginLeft: "0.5rem",width:'5rem'
                        }}
                      >
                        <span style={{ fontFamily: "Inter-Medium",color: "#45B21E", padding: "8px",fontSize:'1rem' }}>
                          Active
                        </span>
                      </button>
                    </span>
                  </p>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <p
                    style={{
                      fontFamily: "Inter-Medium",
                      fontSize: "14px",
                      color: "#919191",paddingLeft: "5px"
                    
                    }}
                  >
                    Loan Id :{" "}
                    <span style={{fontFamily: "Inter-Medium", color: "#D32028" }}>{sessionStorage.getItem("LoanId")} </span>
                  </p> 
                 
                 
                 
                </Grid>
               
              </Grid>   
            <Grid container style={{boxShadow: "0px 3px 3px 0px #D320281A",backgroundColor:'#FFF8F4',borderRadius:'12px',borderStyle:"solid",borderWidth:'1px',borderColor:'#F9D8D6',padding:'20px',marginTop:'20px'}}>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',color:'black',fontWeight:'500',marginBottom:'10px'}}>Outstanding amount</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'18px',fontWeight:'400',color:'#D32028'}}>₹ {formatNumberWithCommas(repaymentdetails?.outstandingAmount)}</p>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',color:'#737373',fontWeight:'500',marginBottom:'10px'}}>EMI start date</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'18px',fontWeight:'400'}}>{repaymentdetails.startDate ? moment(repaymentdetails.startDate).format('DD MMM YY') : null }</p>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',color:'#737373',fontWeight:'500',marginBottom:'10px'}}>EMI end date</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'18px',fontWeight:'400'}}>{repaymentdetails?.endDate}</p>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',color:'#737373',fontWeight:'500',marginBottom:'10px'}}>EMI amount</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'18px',fontWeight:'400'}}>₹ {formatNumberWithCommas(repaymentdetails?.emi)}</p>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',color:'#737373',fontWeight:'500',marginBottom:'10px'}}>Pending EMI amount</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'18px',fontWeight:'400'}}>₹ {emiHistory?.outstandingAmount}</p>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',color:'#737373',fontWeight:'500',marginBottom:'10px'}}>Principal amount</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'18px',fontWeight:'400',color:'#D32028'}}>₹ {formatNumberWithCommas(repaymentdetails?.loanAmount)}</p>
              </Grid>
            </Grid>
            
            <div style={{borderStyle:'solid',borderWidth:'1px',borderColor:'#DBDBDB',borderRadius:'10px',boxShadow:' 0px 0px 3px 0px #00000026',marginTop:'30px',overflowX:'auto'}}>
               <Grid container>
                <Grid item xs={12} sm={12} md={7} lg={8} style={{display:'flex',paddingLeft:'3%',paddingTop:'2%'}}>
                <img style={{height:'30px',width:'28px'}} src={EMI}/>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',padding:'1%'}}>EMI History</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',padding:'1%',marginLeft:'3%',fontWeight:'600'}}>{count}/{totalCount} EMIs completed</p>
                </Grid>
                <Grid item xs={12} sm={8} md={5} lg={4} style={{padding:'2%'}}>
                  <div style={{borderStyle:'solid',borderColor:'#A5A5A5',borderRadius:'14px',borderWidth:'1px',display:'flex',justifyContent:'space-between',padding:'10px',alignItems:'center'}}>
                    <div style={{fontFamily:'Inter-Medium'}}>
                      {checkedCount}EMIs
                    </div>
                    <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                    <div style={{fontFamily:'Inter-Medium'}}> ₹{totalEmiAmount?totalEmiAmount:"0"}</div>
                    <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                    <div style={{backgroundColor:'#CB1F26',borderRadius:'14px',paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',cursor:'pointer'}}><span ><img style={{height:'12px',width:'20px',marginRight:'5px'}} src={wallet}/><span style={{fontFamily:'Inter-Medium',color:'white'}}>Pay now</span></span></div>

                  </div>
               
                </Grid>
               </Grid>
               {isMobile ? (
                <>
                 <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <thead style={{backgroundColor:'#F9FAFB'}}>
        <tr>
        <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Select</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Due Date</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>EMI</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Paid Date</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Status</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Receipt</th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((item, index) => (
          <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'10px' }}><input id={index}   disabled={item?.status==2} type="checkbox" onClick={() => handleCheckboxClick(tableData,index)}  /></td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{item.dueDate?.substr(0, 10) ? moment(item.dueDate?.substr(0, 10)).format('DD MMM YY') : "-" }</td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{item.emiAmount}</td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{item.emiRepaidDate?.substr(0, 10) ? moment(item.emiRepaidDate?.substr(0, 10)).format('DD MMM YY') : "-"}</td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>
            {item.status?
                                  item.status == 1?<span style={{color:'#6E24E7', fontFamily:'Inter-Medium',fontSize:'12px'}}>UnPaid</span>
                                  :item.status==3?<span style={{color:'#F8A31F', fontFamily:'Inter-Medium',fontSize:'12px'}}>PartlyPaid</span>
                                  :item.status==2?<span style={{color:'#12B76A',fontFamily:'Inter-Medium',fontSize:'12px'}}>Paid</span>
                                  :item.status==4?<span style={{color:'#D22129',fontFamily:'Inter-Medium',fontSize:'12px'}}>OvdPayment</span>
                                  :item.status==5?<span style={{color:'#D22129',fontFamily:'Inter-Medium',fontSize:'12px'}}>Ovd</span>
                                  :item.status==6?<span style={{color:'#F8A31F', fontFamily:'Inter-Medium',fontSize:'12px'}}>Pause</span>
      
                                  :item.status
                                  :'-'}
              </td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{item?.status === 2 ? <img src={download} style={{ height: '17px',cursor:'pointer' }} /> : '-'}</td>
          </tr>
        ))}
      </tbody>
    </table>
            
                </>
           
          ) : (
            <div className="tables1">
            <table className={`table-of-contents1 ${isMobile ? 'mobile' : 'desktop'}`} >
                          <thead style={{backgroundColor:'#F9FAFB'}}>
                          <tr>
                          <th >Select</th>
                              <th >DueDate</th>
                              <th >EMI</th>
                              <th  >Paid Date</th>
                              <th >Status</th>
                              {/* <th >Remarks</th> */}
                              <th >Receipt</th>
      
      
                          </tr>
                          </thead>
                          <tbody>
                          {tableData?.map((item,index) => (
                              <tr key={index}>
                              <td ><input id={index}   disabled={item?.status==2} type="checkbox" onClick={() => handleCheckboxClick(tableData,index)}  /></td>
                              <td >{item.dueDate?.substr(0, 10) ? moment(item.dueDate?.substr(0, 10)).format('DD MMM YY') : "-" }</td>
                              <td>{item.emiAmount}</td>
                              <td >{item.emiRepaidDate?.substr(0, 10) ? moment(item.emiRepaidDate?.substr(0, 10)).format('DD MMM YY') : "-" }</td>
                              <td>  {item.status?
                                  item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>UnPaid</span>
                                  :item.status==3?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>PartlyPaid</span>
                                  :item.status==2?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Paid</span>
                                  :item.status==4?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>OvdPayment</span>
                                  :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Ovd</span>
                                  :item.status==6?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Pause</span>
      
                                  :item.status
                                  :'-'}</td>
                              {/* <td>
                              <Tooltip title="Remarks" arrow>
                                <img src={icon} style={{height:'20px'}}/>
                              </Tooltip>
                                </td> */}
                                <td>
                                {item?.status === 2 ?<div onClick={() => handleClick(<App1 receipt={{...item,firstname:repaymentdetails.firstName,loanType:repaymentdetails.courseName}} type="repayment" />, 'Receipt')}> <App1 type="repayment" receipt={{...item,firstname:repaymentdetails.firstName,loanType:repaymentdetails.courseName}}/> </div> : '-'}
                                </td>
                              </tr>
                          ))}
                          </tbody>
                      </table>
            </div>
          )}
           
              
      
            </div>
            
            <div style={{marginTop:'30px'}}>
            <Educationcard />
            </div>
           
          </Paper>
        </Container>
      </Homestyle>
     
        <Footer/>
    </div>
  )
}