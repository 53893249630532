import Navbar from "./Navbar";
import React, { useState, useEffect } from 'react';
import { Container, Button, Grid, Paper, Box, colors } from "@mui/material";
import { Homestyle } from "../Home/Style";
import back from "../../Assets/images/arrow-left-circle.png";
import { useNavigate } from "react-router-dom";
import "./Emihistory.css"
import Educationcard from "../Home/Educationcard";
import emihistory from "../../Assets/images/emihistory.png"
import axios from "axios";
import * as moment from 'moment'

export default function Emihistory(){

    const user = sessionStorage.getItem("token");

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [tableData,setTableData] = useState([])
    const [count, setCount] = useState(0); 
    const [totalCount, setTotalCount] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {

        const url = `${process.env.REACT_APP_DASHBOARD_URL}/emis/for-loan?loanId=${sessionStorage.getItem("LoanId")}`
         
        axios.get(url,{
            headers:{
              Authorization:`Bearer ${user}`
          }
          }).then((res)=>{ const sortedTableData = res?.data?.data.sort((a, b) => a.emiNo - b.emiNo);
            const unpaidItems = sortedTableData.filter(item => item.status === 2);
            setCount(unpaidItems.length);
            setTotalCount(sortedTableData.length);
            setTableData(sortedTableData);
        })



        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };


        

      }, []);



      
    return(
        <div className="home-bg ">
      <Navbar />
      <Homestyle>
        <Container>
          <Paper className="paper-setting">
          <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <div
              onClick={() => navigate(-1)}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img style={{ height: "22px", width: "22px" }} src={back} />
              <p
                style={{
                  fontFamily: "Inter-Medium",
                  fontSize: "14px",
                  fontWeight: "500",
                  padding: 0,
                  margin: 0,
                  paddingLeft: "5px",
                }}
              >
                Back
              </p>
            </div>
            
                  </div>
                
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
                <img src={emihistory} style={{height:'25px'}}/>
                <p style={{fontFamily:'Outfit-Medium',padding:'5px',marginTop:'1rem'}}>EMI History </p>
                <p style={{fontFamily:'Outfit-Medium',padding:'5px',marginTop:'1rem',marginLeft:'0.5rem'}}>{count}/{totalCount}</p>
                <p style={{fontFamily:'Outfit-Medium',padding:'5px',marginTop:'1rem'}}>Completed</p>
              </div>
                </Grid>
              </Grid>
      <div className="tables">
      <table className={`table-of-contents ${isMobile ? 'mobile' : 'desktop'}`} >
                    <thead style={{backgroundColor:'#F9FAFB'}}>
                    <tr>
                    <th >Emi No.</th>
                        <th >DueDate</th>
                        <th >EMI</th>
                        <th  >Paid Date</th>
                        <th >Status</th>

                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item,index) => (
                        <tr key={index}>
                        <td >{item.emiNo}</td>
                        <td >{moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</td>
                        <td>{item.emiAmount}</td>
                        <td >{moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</td>
                        <td>  {item.status?
                            item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>UnPaid</span>
                            :item.status==3?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>PartlyPaid</span>
                            :item.status==2?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Paid</span>
                            :item.status==4?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>OvdPayment</span>
                            :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Ovd</span>
                            :item.status==6?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Pause</span>

                            :item.status
                            :'-'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
      </div>
                
                <div style={{ marginTop: "30px" }}>
              <Educationcard />
            </div>
          </Paper>
        </Container>
      </Homestyle>
    </div>
    )
}