import React, { useContext } from "react";
import styles from "./index.module.css";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { createSelfie } from "../../services/sanctions";
let Digio: any;

function Selfie() {
  const { userId, applicationId, mobileNumber } = useContext(
    DataContext
  ) as DataContextType;
  const navigate = useNavigate();

  const data = {
    entityId: "KID230711213932067TGEW66Y4GUSGIP",
    tokenId: "GWT230711213932085AY48ADQCK5UVDA",
    id: "id_MTY4OTA5MTc3MjAyMy42Mz",
    mob: "8763039380",
  };

  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  function selfieClickHandler() {
    fetchJsFromCDN(process.env.REACT_APP_DIGIO_SDK, ["Digio"]).then(
      (digio: any) => {
        createSelfie(applicationId, userId)
          .then((result) => {
            const { entityId, tokenId } = result.data;

            Digio = digio[0];
            let d = new Digio({
              environment: process.env.REACT_APP_DIGIO_ENV,
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: data.entityId,
              digioUserIdentifier: mobileNumber,
              digioLoginToken: data.tokenId,
              is_iframe: true,
              callback: (_digio: any) => {
                console.log("ALL: ", _digio);

                if (_digio.error_code === "CANCELLED") {
                  console.log("Flow cancelled by user");
                  navigate("/sanctions/agreement");
                  return;
                }
                if (_digio.error_code !== undefined) {
                  navigate("/sanctions/agreement");
                  throw new Error(_digio.message);
                }
                navigate("/sanctions/agreement");
              },
            });

            d.init();
            d.submit(entityId, mobileNumber, tokenId);
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <Button
            onPress={() => {
              selfieClickHandler();
            }}
            text={"Selfie"}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Selfie;
