import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { queryStringToObject } from "../../utils/queryStringToObject";

function AccountAggregatorSuccess() {
  const loc = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const setAAStatus = async (status: any) => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${"authToken.value"}`);

        const response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/finbox/status`,
          {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({ ...status,userId:loc.state.data.userId }),
          }
        );
        const body = { ...status, userId: loc?.state?.data?.userId };
        console.log("body-->", body);
        const result = await response.json();
        console.log(result);

        navigate("/congratulations");
      } catch (error) {
        console.log("Error:", error);
      }
    };

    if (loc.search) {
      console.log(queryStringToObject(loc.search));
      setAAStatus(queryStringToObject(loc.search));
    } else {
      navigate("/congratulations");
    }
  }, [loc]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          textAlign: "center",
        }}
      >
        Your Bank Statement has been successfully sent through bank by OTP
      </h1>
    </div>
  );
}

export default AccountAggregatorSuccess;
