import React from 'react';
import * as moment from 'moment'
import { Document, Page, Text, View,Image } from '@react-pdf/renderer';
import FeemonkLogo from '../../../Assets/images/FeeMonk_HighRes_Logo.png'

const font = {
  fontFamily: 'Helvetica',
};

const Statement = ({ company, user }) => {
  const currentDate = new Date().toLocaleDateString();
  const currentTime = new Date().toLocaleTimeString();

  return (
    <Document>
      <Page size="A3">
        {/* Header */}
        <View style={{ padding: 20, ...font, fontSize: 10 }}>
          <View style={{ marginBottom: 10, flexDirection: 'column', alignItems: 'center' }}>
            <Image src={FeemonkLogo} style={{width:80}}/>
            <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold', marginBottom: 5, textAlign: 'center' }}>{company.name}</Text>
            <Text style={{ fontSize: 10, textAlign: 'center' }}>{company.address}</Text>
            <Text style={{ fontSize: 10, fontFamily: 'Helvetica', textAlign: 'center', marginBottom: 10 ,marginTop: 2}}>Instalment Report AS ON : {currentDate}</Text>
            <Text style={{fontFamily: 'Helvetica'}}>{user.loanType} ({user.username}-{user.LoanID})</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
              <Text>Printed on: {currentDate} {currentTime}</Text>
              <Text>                                                                        </Text>
              {/* <Text style={{fontFamily: 'Helvetica'}}>Gachibowli</Text> */}
            </View>
          </View>
          
          {/* Dots below the header */}
          <View style={{ marginTop: 10, marginBottom: 10, height: 1, backgroundColor: 'black' }} />

          {/* Statement table */}
          <View>
            {/* First table */}
           
              <View style={{ border:'1px solid #000',borderBottom:"0px solid #000",flexDirection: 'row' ,display:'flex',justifyContent:'space-evenly'}}>
                {/* <Text style={styles.cell}>EMI id</Text> */}
                <Text style={styles.cell}>Instalment No.</Text>
                <Text style={styles.cell}>Instalment Principle</Text>
                <Text style={styles.cell}>Interest Amount</Text>
                <Text style={styles.cell}>Instalment Amount</Text>
                <Text style={styles.cell}>Instalment Date</Text>
                <Text style={styles.cell}>Paid Principle</Text>
                <Text style={styles.cell}>Paid Interest</Text>
                <Text style={styles.cell}>Paid Penalty</Text>
                <Text style={styles.cell}>Paid Amount</Text>
                <Text style={styles.cell}>Paid Date</Text>
              </View>
              {user?.loanDetails?.map((item,index)=>(
             
              <View style={{  border:'1px solid #000',flexDirection: 'row',display:'flex',justifyContent:'space-evenly',}}>
                {/* <Text style={styles.infoEMI}>{`${item?.emiId?.substring(0,13)} ${item?.emiId?.substring(14,29)} ${item?.emiId?.substring(30,40)} ${item?.emiId?.substring(41,item?.emiId?.length)} `}</Text> */}
                <Text style={styles.info}>{item.emiNo}</Text>
                <Text style={styles.info}>{item.principleAmount}</Text>
                <Text style={styles.info}>{item.interestAmount}</Text>
                <Text style={styles.info}>{item.emiAmount}</Text>
                <Text style={styles.info}>{moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</Text>
                <Text style={styles.info}>{item.paidPrinciple}</Text>
                <Text style={styles.info}>{item.paidInterest}</Text>
                <Text style={styles.info}>{item.paidOverdue}</Text>
                <Text style={styles.info}>{item.repaidEmiAmount}</Text>
                <Text style={styles.info}>{moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</Text>
              </View>
              )
              )
            }
             <View style={{  border:'1px solid #000',flexDirection: 'row',display:'flex',justifyContent:'space-evenly',}}>
                <Text style={styles.infoBold}>    </Text>
                <Text style={styles.infoBold}>{user?.loanDetails?.reduce((total,item)=>total+item.principleAmount,0)}</Text>
                <Text style={styles.infoBold}>{user?.loanDetails?.reduce((total,item)=>total+item.interestAmount,0)}</Text>
                <Text style={styles.infoBold}>{user?.loanDetails?.reduce((total,item)=>total+item.emiAmount,0)}</Text>
                <Text style={styles.infoBold}>{}</Text>
                <Text style={styles.infoBold}>{user?.loanDetails?.reduce((total,item)=>total+item.paidPrinciple,0)}</Text>
                <Text style={styles.infoBold}>{user?.loanDetails?.reduce((total,item)=>total+item.paidInterest,0)}</Text>
                <Text style={styles.infoBold}>{user?.loanDetails?.reduce((total,item)=>total+item.paidOverdue,0)}</Text>
                <Text style={styles.infoBold}>{user?.loanDetails?.reduce((total,item)=>total+item.repaidEmiAmount,0)}</Text>
                <Text style={styles.infoBold}>{}</Text>
              </View>
            </View>
              
              {/* Second table */}
              {/* <View style={{ marginTop:'-20%',border:'1px solid #000',borderBottom:"0px solid #000",flexDirection: 'row' ,display:'flex',justifyContent:'space-evenly'}}>
                <Text style={styles.cell2}>Finance Amt(Rs.)</Text>
                <Text style={styles.cell2mid}>Finance Charges(Rs.) </Text>
                <Text style={styles.cell2}>Contract Value(Rs.)</Text>
            
              </View>
              <View style={{ height:'6%', border:'1px solid #000',flexDirection: 'row',display:'flex',justifyContent:'space-evenly',}}>
                <Text style={styles.info2}>{user.financeAmt}</Text>
                <Text style={styles.info2mid}>{user.financeCharges}</Text>
                <Text style={styles.info2}>{user.contractValue}</Text>
              </View> */}

              {/* Third table */}
              {/* <View>
              <View style={{ marginTop:'2%',border:'1px solid #000',borderBottom:"0px solid #000",flexDirection: 'row' ,display:'flex',justifyContent:'space-evenly'}}>
                <Text style={styles.cell3R}>Due Particulars</Text>
                <Text style={styles.cell2mid}>Finance Charges(Rs.) </Text>
                <Text style={styles.cell3L}>Payment Particulars</Text>
            
              </View>
              <View style={{ border:'1px solid #000',borderBottom:"0px solid #000",flexDirection: 'row' ,display:'flex',justifyContent:'space-evenly'}}>
                <Text style={styles.cell}>Doc Reg No</Text>
                <Text style={styles.cell}>Descrption </Text>
                <Text style={styles.cell}>EMI DATE </Text>
                <Text style={styles.cell}>PENAL AMOUNT</Text>
                <Text style={styles.cell}>Amount(Rs.)</Text>
                <Text style={styles.cell}>Doc Ref No.</Text>
                <Text style={styles.cell}>Description </Text>
                <Text style={styles.cell}>Transaction Dt</Text>
                <Text style={styles.cell}>Amount</Text>
              </View>
              {user?.loanDetails?.map((detail, index) => (
                <View key={index} style={{ height:'7%', border:'1px solid #000',flexDirection: 'row',display:'flex',justifyContent:'space-evenly',}}>
                  <Text style={styles.info}>{detail.DocReg}</Text>
                  <Text style={styles.info}>{detail.Description}</Text>
                  <Text style={styles.info}>{moment(detail.emiDate).format("DD MMM YYYY")}</Text>
                  <Text style={styles.info}>{detail.PenalAmount}</Text>
                  <Text style={styles.info}>{detail.Amount}</Text>
                  <Text style={styles.info}>{detail.DocRef}</Text>
                  <Text style={styles.info}>{detail.Description}</Text>
                  <Text style={styles.info}>{moment(detail.TransactionDate).format("DD MMM YYYY")!=="Invalid date"?moment(detail.TransactionDate).format("DD MMM YYYY"):'--'}</Text>
                  <Text style={styles.info}>{detail.Amount+detail.PenalAmount}</Text>
                </View>
                ))}
              </View> */}
          </View> 

          {/* Footer */}
          
        
      </Page>
    </Document>
  );
};

const styles = {
  cell: {
    width:'10%',
    padding: 4,
    color:'#800000',
    fontFamily:'Helvetica',
    borderRight:'1px solid #000',
    borderBottom:"0px solid #000",
    textAlign: 'center',
  },
  info: {
   width:'13%',
    paddingTop:'1%',
    borderRight:'1px solid #000',
    flexWrap: 'wrap',
    borderStyle: 'solid',
    textAlign: 'center',
  },
  infoEMI: {
   width:'13%',
    paddingTop:'1%',
    borderRight:'1px solid #000',
    flexWrap: 'wrap',
    borderStyle: 'solid',
    textAlign: 'left',
  },
  infoBold: {
    width:'13%',
    paddingTop:'1%',
    borderRight:'1px solid #000',
    flexWrap: 'wrap',
    fontFamily:'Helvetica-Bold',
    borderStyle: 'solid',
    textAlign: 'center',
  },
};

export default Statement;
