import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Statement from './Statement';

const company = {
  name: "MONK CAPITAL PVT LTD",
  address: `The Platina, B-406, Sy No. 132, 134, H. No. 4-50/1, Gachibowli, K.V.Rangareddy, Seri Lingampally, Telangana, India, 500032`,
};





const App = ({repaymentDet,emiDet}) => {
console.log(repaymentDet,emiDet)
  const user = {
  loanType: "Consumer Loan",
  loanNumber: emiDet?.map((item,index)=>{ if(index===1) return(item.loanId)}),
  loanDate: repaymentDet.startDate,
  tenure: repaymentDet.tenure,
  numOfEmis: emiDet?.length,
  Description: "INSTALMENT",
  loanStatus: repaymentDet?.status==="1"?"ACTIVE":"CLOSED",
  username: repaymentDet.firstName + " "+repaymentDet.lastName,
  LoanID: emiDet?.map((item,index)=>{ if(index===1) return(item.loanId)}),
  loanDetails: emiDet
};
const date = new Date().toLocaleDateString();
const fileName = `Loan_Statement_${user.username}_${date}.pdf`;

return(

  <PDFDownloadLink document={<Statement company={company} user={user} />} fileName={fileName} style={{textDecoration:'none'}}>
  <span style={{
                       fontFamily: "Inter-Medium",
                       fontSize: "14px"
                       ,whiteSpace:'nowrap',textDecoration:'none',color:'black'
                     }}>Loan Statement</span>
  </PDFDownloadLink>
)
};

export default App;
