// import React from "react";

// function FeemonkText() {
//   return (
//     <span
//       style={{
//         display: "inline",
//         fontFamily: "Outfit",
//       }}
//     >
//       <p
//         style={{
//           display: "inline",
//           color: "#D32028",
//           fontWeight: "bold",
//           fontSize: "1em",
//         }}
//       >
//         Fee
//       </p>
//       <p
//         style={{
//           display: "inline",
//           color: "#000000",
//           fontWeight: "bold",
//           fontSize: "1em",
//         }}
//       >
//         Monk
//       </p>
//     </span>
//   );
// }

// export default FeemonkText;
import React from "react";

function FeemonkText() {
  return (
    <span
      style={{
        display: "inline",
        fontFamily: "Outfit",
      }}
    >
      <p
        style={{
          display: "inline",
          color: "#D32028",
          fontWeight: "bold",
          fontSize: "1em",
        }}
      >
        Monk
      </p>
      <p
        style={{
          display: "inline",
          color: "#000000",
          fontWeight: "bold",
          fontSize: "1em",
        }}
      >
        Capital
      </p>
    </span>
  );
}

export default FeemonkText;
