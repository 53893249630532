import React from "react";
import Styles from "./index.module.css";

export interface LabelProps {
  text: string;
  left?: boolean;
}

function Label({ text, left }: LabelProps) {
  return (
    <label
      className={Styles.labelText}
      style={{ margin: left ? "0 0.5rem" : "auto" }}
    >
      {text}
    </label>
  );
}

export default Label;
