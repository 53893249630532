import React from "react";
// import FeemonkLogo from "../../../images/hero_icon.svg";
// import ProfileIcon from "../../../images/icons/profile.svg";
// import FeemonkLogo from "../../../images/IMG_0793-removebg-preview.png";
import MonkCapitalLogo from "../../../images/monk_capital_logo_edit.png";
import FeemonkLogo from "../../../../images/FeeMonk-HighRes-Logo.png";
import ProfileIcon from "../../../images/icons/profile_insurefin.svg";
import DownloadIcon from "../../../images/icons/download.svg";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";


function Navbar() {
  const token = sessionStorage.getItem("data") as string

  const location = useLocation();
  console.log("decode", location?.state?.data);

  return (
    <div className={styles.navbar}>
      <Link to="/mainpage">
        <img
          style={{ width: "12rem", height:location?.state?.data?.channelId === 5? "2rem" :"5rem"}}
          src={location?.state?.data?.channelId === 5 ? MonkCapitalLogo:FeemonkLogo}
          alt=""
          className={styles.logo}
        />
      </Link>
      <div className={styles.footerRight}>
        {/* <div className={styles.downloadButton}>
          <p style={{ fontSize: "1rem", textAlign: "center" }}>Download App</p>
        </div> */}
        {/* <img
          style={{ width: "3rem", height: "3rem" }}
          src={ProfileIcon}
          alt=""
        /> */}
      </div>
    </div>
  );
}

export default Navbar;
