import React, { useState } from "react";
import Button from "../../components/atoms/Button";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../../images/icons/arrow_left.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import BankIcon from "../../images/static_assests/bank.svg";
import PdfIcon from "../../images/static_assests/pdf.svg";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { BANKS } from "../../utils/banks_list";

function AccountAggregator() {
  const [banksList] = useState(BANKS.banks);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedMethod, setselectedMethod] = useState<
    "PDF" | "AA" | "NETBANKING" | ""
  >("");
  const navigate = useNavigate();

  const getAARedirectLink = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      //   myHeaders.append("Authorization", `Bearer ${authToken.value}`);

      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/generate`,
        {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({}),
        }
      );

      const result = await response.json();

      window.open(result.data.url, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const submit = () => {
    //
    if (selectedMethod === "AA") {
      getAARedirectLink();
    } else if (selectedMethod === "PDF") {
      navigate("/account-aggregator/bank-statement");
    } else {
      navigate("/account-aggregator/bank-statement");
    }
  };

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <h3>Account Aggregator</h3>
          <select
            onChange={(e) => {
              setSelectedBank(e.target.value);
              setselectedMethod("");
            }}
          >
            <option value="" defaultChecked>
              Select Bank
            </option>
            {banksList.map((bank) => {
              return (
                <option
                  value={bank.name}
                  key={bank.name}
                  style={{
                    background: `url(${bank.logo_url}) no-repeat left center`,
                  }}
                >
                  {bank.full_name}
                </option>
              );
            })}
          </select>
          {selectedBank && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "2rem",
              }}
            >
              {banksList.filter((bank) => bank.name === selectedBank)[0]
                .is_aa_available && (
                <div
                  onClick={() => {
                    setselectedMethod("AA");
                  }}
                  style={{
                    borderRadius: "1rem",
                    border:
                      selectedMethod === "AA"
                        ? "2px solid #D32028"
                        : "1px solid var(--primary-border)",
                    background: "var(--box-background-dark)",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                    padding: "2rem 3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                    }}
                    src={BankIcon}
                    alt=""
                  />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    Bank Account
                  </p>
                </div>
              )}
              {banksList.filter((bank) => bank.name === selectedBank)[0]
                .is_pdf_upload_available && (
                <div
                  onClick={() => {
                    setselectedMethod("PDF");
                  }}
                  style={{
                    borderRadius: "1rem",
                    border:
                      selectedMethod === "PDF"
                        ? "2px solid #D32028"
                        : "1px solid var(--primary-border)",
                    background: "var(--box-background-dark)",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                    padding: "2rem 3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                    }}
                    src={PdfIcon}
                    alt=""
                  />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    PDF Upload
                  </p>
                </div>
              )}
              {banksList.filter((bank) => bank.name === selectedBank)[0]
                .is_net_banking_available && (
                <div
                  onClick={() => {
                    setselectedMethod("NETBANKING");
                  }}
                  style={{
                    borderRadius: "1rem",
                    border:
                      selectedMethod === "NETBANKING"
                        ? "2px solid #D32028"
                        : "1px solid var(--primary-border)",
                    background: "var(--box-background-dark)",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                    padding: "2rem 3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                    }}
                    src={BankIcon}
                    alt=""
                  />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    Net Banking
                  </p>
                </div>
              )}
            </div>
          )}
          <Button
            onPress={() => {
              submit();
            }}
            imageRight={ArrowLeft}
            text={"Continue"}
            disabled={!(selectedMethod && selectedBank)}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AccountAggregator;
