// import { process.env.REACT_APP_DASHBOARD_URL } from "../utils/keys";

export const bankStatementPdfUpload = async (data: any) => {
  return fetch(
    `${process.env.REACT_APP_DASHBOARD_URL}/bank-statement-analysis/finbox/upload`,
    {
      method: "POST",
      // headers: {
      // Accept: "*/*",
      // "Content-Type": "multipart/form-data",
      // Authorization: `Bearer ${data.token}`,
      // },
      // body: JSON.stringify(data),
      body: data,
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return console.error(error);
    });
};
