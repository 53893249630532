import wallet from "../../Assets/images/wallet.png";
import React, { useState, useEffect } from "react";
import close from "../../Assets/images/close.png";
import visa from "../../Assets/images/visa.png";
import add from "../../Assets/images/add.png";
import gpay from "../../Assets/images/gpay.png";
import phpay from "../../Assets/images/phpay.png";
import paytm from "../../Assets/images/paytm.png";
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Spinner,
  FormGroup,
} from "reactstrap";

export default function Emipayment() {
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [modal, setModal] = useState(false);

 

  const popUpStatusForm = () => {
    setStatusOpen(!formStatusOpen);
  };

  // Function to handle radio button change
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardSelection = (cardId) => {
    setSelectedCard(cardId);
  };

  const cardStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  };

  const labelStyle = {
    cursor: "pointer",
    borderRadius: "5px",
    marginLeft: "10px",
  };

  const radioButtonStyle = {
   
    appearance: "none",
    width: "20px",
    height: "20px",
    border: "2px solid #D32028",
    borderRadius: "50%",
    outline: "none",
    cursor: "pointer",margin:'10px'

  };

  const selectedRadioButtonStyle = {
    ...radioButtonStyle,
    border: selectedCard === "card1" ? "2px solid red" : "2px solid #D32028",
    backgroundColor: "#D32028",
  };

  return (
    <>
      <Button
        // onClick={popUpStatusForm}
        style={{
          boxShadow: " 0px 3px 3px 0px #0000002",
          cursor: "pointer",
          width: '120px',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#D32028",
          border: "none",
          borderRadius: "30px",
          height: "2.2em",
          padding: "10px",
        }}
      >
        <img style={{width:'1.2em',height:'1.2em'}} src={wallet} />
        <p
          style={{
            color: "white",
            fontFamily: "Inter-Medium",
            margin: 0,
            padding: 0,
            fontSize:'1em',
            paddingLeft: "5px",
          }}
        >
          Pay EMI
        </p>
      </Button>
      <Modal isOpen={formStatusOpen}  >
        <ModalHeader toggle={popUpStatusForm} style={{backgroundColor: "#E9E9E9"}}>EMI Payment</ModalHeader>
        <ModalBody    style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}>
  
            
              <div>
                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <p
                    style={{
                      fontFamily: "Inter-Medium",
                      fontSize: "15px",
                      fontWeight: "600",
                      marginLeft: "10px",
                    }}
                  >
                    Credit and Debit cards
                  </p>
                  <div
                    style={{
                      boxShadow: "0px 2px 2px 0px #0000001A",
                      backgroundColor: "#FFF8F4",
                      marginTop: "10px",
                      width: "100%",
                      borderRadius: "10px",
                      borderColor: "#F9D8D6",
                      borderStyle: "solid",
                      borderWidth: "1px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      
                      }}
                    >
                      <div style={{ ...cardStyle }}>
                        <label htmlFor="card1" style={labelStyle}>
                          <div>
                            <p>
                              {" "}
                              <img
                              
                                src={visa}
                              />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  fontFamily: "Inter-Medium",
                                  fontWeight: "500",
                                }}
                              >
                                HDFC Bank
                                <br />
                                <span
                                  style={{
                                    marginLeft: "25px",
                                    fontSize: "12px",
                                    fontFamily: "Inter-Medium",
                                    color: "#515151",
                                  }}
                                >
                                  ********2193
                                </span>
                              </span>
                            </p>
                          </div>
                        </label>
                        <input
                          type="radio"
                          name="selected-card"
                          id="card1"
                          onChange={() => handleCardSelection("card1")}
                          checked={selectedCard === "card1"}
                          style={
                            selectedCard === "card1"
                              ? selectedRadioButtonStyle
                              : radioButtonStyle
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        border: "1px solid #DBDBDB",
                        width: "100%",
                      
                      }}
                    ></div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "#FFF8F4",
                      }}
                    >
                      <div style={{ ...cardStyle }}>
                        <label htmlFor="card2" style={labelStyle}>
                          <div >
                            <p>
                              {" "}
                              <img
                                style={{ backgroundColor: "#F9D8D6" }}
                                src={visa}
                              />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  fontFamily: "Inter-Medium",
                                  fontWeight: "500",
                                }}
                              >
                                HDFC Bank
                                <br />
                                <span
                                  style={{
                                    marginLeft: "25px",
                                    fontSize: "12px",
                                    fontFamily: "Inter-Medium",
                                    color: "#515151",
                                  }}
                                >
                                  ********2193
                                </span>
                              </span>
                            </p>
                          </div>
                        </label>
                        <input
                          type="radio"
                          name="selected-card"
                          id="card2"
                          onChange={() => handleCardSelection("card2")}
                          checked={selectedCard === "card2"}
                          style={
                            selectedCard === "card2"
                              ? selectedRadioButtonStyle
                              : radioButtonStyle
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        border: "1px solid #DBDBDB",
                        width: "100%",
                        
                      }}
                    ></div>

                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#FFF8F4",
                    
                      }}
                    >
                      <p style={{ display: "flex", alignItems: "center" ,padding:'10px'}}>
                        {" "}
                        <img src={add} />
                        <span
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            fontFamily: "Inter-Medium",
                            fontWeight: "500",
                            color: "#11A744",
                          }}
                        >
                          Add new card
                          <br />
                          <span
                            style={{
                              fontSize: "12px",
                              fontFamily: "Inter-Medium",
                              color: "#515151",
                            }}
                          >
                            Save and pay via cards
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    marginTop: "20px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Inter-Medium",
                      fontSize: "15px",
                      fontWeight: "600",
                      marginLeft: "10px",
                    }}
                  >
                    UPI
                  </p>
                  <div
                    style={{
                      boxShadow: "0px 2px 2px 0px #0000001A",
                      backgroundColor: "#FFF8F4",
                      marginTop: "10px",
                      width: "100%",
                      
                      borderRadius: "10px",
                      borderColor: "#F9D8D6",
                      borderStyle: "solid",
                      borderWidth: "1px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      
                      }}
                    >
                      <div style={{ ...cardStyle }}>
                        <label htmlFor="card1" style={labelStyle}>
                          <div>
                            <p
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {" "}
                              <img src={gpay} />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  fontFamily: "Inter-Medium",
                                  fontWeight: "500",
                                }}
                              >
                                Google Pay
                              </span>
                            </p>
                          </div>
                        </label>
                        <input
                          type="radio"
                          name="selected-card"
                          id="card1"
                          onChange={() => handleCardSelection("card3")}
                          checked={selectedCard === "card3"}
                          style={
                            selectedCard === "card3"
                              ? selectedRadioButtonStyle
                              : radioButtonStyle
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        border: "1px solid #DBDBDB",
                        width: "100%",
                       
                      }}
                    ></div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "#FFF8F4",
                      }}
                    >
                      <div style={{ ...cardStyle }}>
                        <label htmlFor="card2" style={labelStyle}>
                          <div>
                            <p
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {" "}
                              <img src={phpay} />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  fontFamily: "Inter-Medium",
                                  fontWeight: "500",
                                }}
                              >
                                Phone Pe
                              </span>
                            </p>
                          </div>
                        </label>
                        <input
                          type="radio"
                          name="selected-card"
                          id="card2"
                          onChange={() => handleCardSelection("card4")}
                          checked={selectedCard === "card4"}
                          style={
                            selectedCard === "card4"
                              ? selectedRadioButtonStyle
                              : radioButtonStyle
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        border: "1px solid #DBDBDB",
                        width: "100%",
                       
                      }}
                    ></div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        
                      }}
                    >
                      <div style={{ ...cardStyle }}>
                        <label htmlFor="card2" style={labelStyle}>
                          <div>
                            <p
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {" "}
                              <img src={paytm} />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  fontFamily: "Inter-Medium",
                                  fontWeight: "500",
                                }}
                              >
                                Paytm
                              </span>
                            </p>
                          </div>
                        </label>
                        <input
                          type="radio"
                          name="selected-card"
                          id="card2"
                          onChange={() => handleCardSelection("card5")}
                          checked={selectedCard === "card5"}
                          style={
                            selectedCard === "card5"
                              ? selectedRadioButtonStyle
                              : radioButtonStyle
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                 width:'100%',
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#FFF3F3",
                  borderRadius: "10px",
                  padding: "20px",
                  marginTop:'10%'
                }}
              >
              
                  <p  style={{
                        color: "#D32028",
                        fontFamily: "Inter-Medium",
                        fontSize: "15px",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}>
                   
                      EMI amount{" "}
                      <span
                        style={{
                          color: "black",
                          fontFamily: "Inter-Medium",
                          fontSize: "16px",
                          fontWeight: "700",
                          marginLeft: "10px",
                        }}
                      >
                        ₹ 25,000
                      </span>
                  
                  </p>
                  <button
                    style={{
                      boxShadow: "0px 3px 3px 0px #00000026",
                      width: "100%",
                      height: "40px",
                      borderRadius: "30px",
                      backgroundColor: "#D32028",
                      borderStyle: "none",padding:'10px',  color: "white",
                      fontFamily: "Inter-Medium",
                      fontSize: "14px",
                    }}
                  >
                   
                      Confirm to Pay
                   
                  </button>
                </div>
                <div>
                 
                </div>
      
         
        </ModalBody>
       
      </Modal>
    </>
  );
}
