import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userActions } from "../../actions/user.actions";
import { useDispatch } from "react-redux";

function queryStringToObject(queryString) {
  if (queryString.startsWith("?")) {
    queryString = queryString.slice(1);
  }

  const pairs = queryString.split("&");
  const result = {};

  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return result;
}

function AdminLogin() {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  console.log(search);
  console.log(queryStringToObject(search));

  useEffect(() => {
    const queryObject = queryStringToObject(search);

    dispatch(userActions.adminLogin(queryObject.mob, queryObject.data));

    navigate("/mainpage");
  }, [search, dispatch, navigate]);

  return (
    <div>
      <h1>Logging in as Admin....</h1>
    </div>
  );
}

export default AdminLogin;
